import React from "react";
import Socialsgrid from "../Socialsgrid/Socialsgrid.jsx";
import goldloxeLogo from "../../Images/goldloxe_white.png";
import bgLeft from "../../Images/profile_street.png";
import bgRight from "../../Images/smiley_background.png";

function FrontPage() {
  return (
    <div className="fp container-fluid p-0 position-relative">
      {/* Background Layer */}
      <div className="fp-background-layer" style={{ backgroundImage: `url(${bgRight})` }}>
        <div 
          className="fp-background-left"
          style={{ backgroundImage: `url(${bgLeft})` }}
        ></div>
      </div>

      {/* Content Layer */}
      <div className="content-layer">
        <div className="logo-container" style={{ backgroundImage: `url(${goldloxeLogo})` }}>
        </div>
        <div className="fp-subtitle">
            <h2>A Technology Adventure</h2>
            <Socialsgrid />
        </div>
    </div>
</div>
  );
};

export default FrontPage;
