import React, {useState} from "react";

import img1 from "../../Images/gifgrid/drawflip2.gif";
import img2 from "../../Images/gifgrid/paint.gif";
import img3 from "../../Images/gifgrid/paste.gif";
import img4 from "../../Images/gifgrid/street5.gif";
import img5 from "../../Images/gifgrid/walkingstreets.gif";
import img6 from "../../Images/gifgrid/robot.gif";




const ResponsiveImageGrid = () => {
  const images = [img1, img2, img3, img5, img6, img4];

  return (

    <div className="container-fluid mt-4">
      <div className="row">
        {images.map((src, index) => (
          <div key={index} className="col-4 col-md-2 col-lg-2 mb-4 d-flex gifgridimg-container">
            <img 
              src={src} 
              alt={`Image ${index + 1}`} 
              className="img-fluid rounded" 
              style={{ aspectRatio: '1', objectFit: 'cover' }} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResponsiveImageGrid;
